























import { Prop, Component } from 'vue-property-decorator'

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// components
import FilesList from '@/components/FilesList.vue'
import Tag from '@/components/_uikit/Tag.vue'
import TiptapEditor from '@/components/_uikit/editor/TiptapEditor.vue'
// store
import AuthModule from '@/store/modules/auth'
import DictionaryModule from '@/store/modules/dictionary'
import { FullUsefulMaterial } from '@/store/types'

@Component({
  components: {
    FilesList,
    Tag,
    TiptapEditor,
  },
})
export default class MasterMaterialCard extends NotifyMixin {
  @Prop({ required: true })
  private maxHeight!: number

  @Prop({ required: true })
  private material!: FullUsefulMaterial

  private get materialTypes() {
    const types: { [key: string]: string } = {}
    DictionaryModule.usefulMaterialsTypes.map(type => types[type.value] = type.name)
    return types
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }
}
